import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { NavParamsService } from 'src/app/services/nav-params.service';
import axios from 'axios';
import config from 'src/config';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-view-order-modal',
  templateUrl: './view-order-modal.page.html',
  styleUrls: ['./view-order-modal.page.scss'],
})
export class ViewOrderModalPage implements OnInit {

  public order: any = {};
  public currentItems: any = [];
  public total: any = 0.00;
  public companyCurrency: any = '';
  public loadingPayment = false;
  public isLoading = true;
  public currentStep = 'select_method';
  public firstName = '';
  public lastName = '';
  public phone = '';
  public email = '';
  public paymentMethod = '';
  public qrCode = '';
  public couponCodeFinal = '';
  public taxRate = 0;
  public transactionNumber: any = '';
  public elementType = NgxQrcodeElementTypes.CANVAS;
  public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  public taxAmount: any = 0.00;
  tenderTypes = [];

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParamsService,
    public navCtrl: NavController,
    public eventsService: EventService,
    public alertCtrl: AlertController
  ) {
    this.total = this.navParams.getNavParams().total;
    this.currentItems = this.navParams.getNavParams().currentItems;
    this.companyCurrency = this.navParams.getNavParams().companyCurrency;
    this.qrCode = this.navParams.getNavParams().qrCode;
    this.transactionNumber = this.navParams.getNavParams().transaction_number;
    this.order = this.navParams.getNavParams().order;
    this.taxAmount = parseFloat(this.navParams.getNavParams().order.tax_amount);

    this.setupCheckout();
  }

  closeTheModal() {
    this.modalCtrl.dismiss();
  }

  async setupCheckout() {
    axios.post(`${config.API_URL}/self-ordering/prepare-checkout`, {
      items: this.currentItems,
      coupon_code: this.couponCodeFinal,
      qr_code: this.qrCode
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.currentItems = data.final_order;
        this.total = data.final_amount;
        // this.tenderTypes = data.tender_types;
        this.isLoading = false;
      }
    }).catch(async (error) => {
      console.error(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  checkout(paymentType = null) {
    this.loadingPayment = true;

    if (paymentType !== null && paymentType !== undefined && paymentType.length > 0) {
      this.paymentMethod = paymentType;
    }

    const jsonData = {
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      email: this.email,
      current_items: this.currentItems,
      payment_method: this.paymentMethod,
      qr_code: this.qrCode,
      transaction_number: this.transactionNumber
    };

    axios.post(`${config.API_URL}/self-ordering/payment-gateway-order-v2`, jsonData)
      .then(res => {
        this.loadingPayment = false;
        const data = res.data;
        if (data.error) {
          this.alertCtrl.create({
            header: 'Error',
            message: data.error_message,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.eventsService.publish('home:clear_cart');
          if (this.paymentMethod !== 'Cash') {
            const paymentUrl = data.payment_url;
            this.modalCtrl.dismiss().then(() => {
              window.open(paymentUrl, '_blank');
            });
          } else {
            this.modalCtrl.dismiss().then(() => {
              this.alertCtrl.create({
                header: 'Success',
                message: data.message,
                buttons: ['Ok']
              }).then(alert => {
                alert.present();
              });
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.loadingPayment = false;
        if (error.response !== undefined && (error.response.status === 422 || error.response.status === '422')) {
          const firstKey = Object.keys(error.response.data.errors)[0];
          const firstError = error.response.data.errors[firstKey][0];
          this.alertCtrl.create({
            header: 'Error',
            message: firstError,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.alertCtrl.create({
            header: 'Error',
            message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        }
      });
  }

  checkoutWithPaymentGateway() {
    this.currentStep = 'payment_gateway';
  }

  checkoutWithCash() {
    this.currentStep = 'cash_payment';
  }

  ngOnInit() {
  }

}
