import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParamsService } from 'src/app/services/nav-params.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-modifier-variant-modal',
  templateUrl: './modifier-variant-modal.page.html',
  styleUrls: ['./modifier-variant-modal.page.scss'],
})
export class ModifierVariantModalPage implements OnInit {

  public product: any = null;
  public finalPrice: any = 0;
  public isLoading = false;
  public currency = null;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParamsService,
    public events: EventService
  ) {
    this.product = this.navParams.getNavParams().product;
    this.finalPrice = this.product.price;
    this.currency = this.navParams.getNavParams().currency;
  }

  updatePrice(type, index) {
    if (type === 'modifier') {
      // Update modifiers
      this.product.product_modifiers[index].checked = !this.product.product_modifiers[index].checked;
    } else {
      // Update variants
      this.product.product_variants.forEach(productVariant => {
        productVariant.checked = 0;
      });
      if (index !== 'default') {
        this.product.product_variants[index].checked = true;
      }
    }

    let basePrice = this.product.price;
    this.product.product_modifiers.forEach(modifier => {
      if (modifier.checked !== null && modifier.checked !== undefined && modifier.checked > 0) {
        basePrice = parseFloat(basePrice) + parseFloat(modifier.price);
      }
    });
    this.product.product_variants.forEach(variant => {
      if (variant.checked !== null && variant.checked !== undefined && variant.checked > 0) {
        basePrice = parseFloat(basePrice) + parseFloat(variant.price);
      }
    });
    this.finalPrice = parseFloat(basePrice).toFixed(2);
  }

  addToCart() {
    this.events.publish('pos_home:add_to_cart', {
      id: this.product.id,
      name: this.product.name,
      price: this.product.price,
      product_variants: this.product.product_variants,
      product_modifiers: this.product.product_modifiers,
      sku: this.product.sku,
      barcode: this.product.barcode
    });
    this.modalCtrl.dismiss();
  }

  closeTheModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
