import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { NavParamsService } from 'src/app/services/nav-params.service';
import axios from 'axios';
import config from 'src/config';

@Component({
  selector: 'app-select-payment-method-modal',
  templateUrl: './select-payment-method-modal.page.html',
  styleUrls: ['./select-payment-method-modal.page.scss'],
})
export class SelectPaymentMethodModalPage implements OnInit {

  public currentItems: any = [];
  public total: any = 0.00;
  public taxableTotal: any = 0.00;
  public companyCurrency: any = '';
  public loadingPayment = false;
  public isLoading = true;
  public currentStep = 'select_method';
  public firstName = '';
  public lastName = '';
  public phone = '';
  public email = '';
  public paymentMethod = '';
  public qrCode = '';
  public couponCodeFinal = '';
  public taxRate = 0;
  public transactionNumber: any = '';
  tenderTypes = [];
  hasMany = false;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParamsService,
    public navCtrl: NavController,
    public eventsService: EventService,
    public alertCtrl: AlertController
  ) {
    this.total = this.navParams.getNavParams().total;
    this.currentItems = this.navParams.getNavParams().currentItems;
    this.companyCurrency = this.navParams.getNavParams().companyCurrency;
    this.qrCode = this.navParams.getNavParams().qrCode;
    this.transactionNumber = this.navParams.getNavParams().transaction_number;
    this.hasMany = this.navParams.getNavParams().hasMany;

    if (!this.hasMany) {
      this.setupCheckout();
      Object.keys(this.currentItems).forEach(key => {
        this.currentItems[key].name = key;
        this.currentItems[key].final_order = this.currentItems[key].items;
      });
    } else {
      this.setupCheckoutMany();
    }
  }

  async setupCheckoutMany() {
    const itemsToPost = new Array();
    const objectKeys = Object.keys(this.currentItems);
    objectKeys.forEach(objectKey => {
      const objectToQuery = this.currentItems[objectKey];
      objectToQuery.items.forEach(item => {
        itemsToPost.push(item);
      });
    });

    axios.post(`${config.API_URL}/self-ordering/multiple/prepare-checkout`, {
      items: itemsToPost,
      coupon_code: this.couponCodeFinal,
      qr_code: this.qrCode
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.currentItems = data.final_order;
        this.total = data.order_summary.total_amount;
        this.taxableTotal = data.order_summary.taxable_amount;
        // this.tenderTypes = data.tender_types;
        this.isLoading = false;
      }
    }).catch(async (error) => {
      console.error(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  closeTheModal() {
    this.modalCtrl.dismiss();
  }

  public taxAmount() {
    return (this.taxRate / 100) * this.taxableTotal;
  }

  async setupCheckout() {
    const firstKey = Object.keys(this.currentItems)[0];
    const itemsToPost = this.currentItems[firstKey].items;
    const qrCode = itemsToPost[0].outlet_qr_code;
    console.log(this.currentItems);

    axios.post(`${config.API_URL}/self-ordering/prepare-checkout`, {
      items: itemsToPost,
      coupon_code: this.couponCodeFinal,
      qr_code: qrCode
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        // this.currentItems = data.final_order;
        this.total = data.final_amount;
        this.taxableTotal = data.taxable_total;
        // this.tenderTypes = data.tender_types;
        this.isLoading = false;
      }
    }).catch(async (error) => {
      console.error(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  checkout(paymentType = null) {
    this.loadingPayment = true;

    if (paymentType !== null && paymentType !== undefined && paymentType.length > 0) {
      this.paymentMethod = paymentType;
    }

    let qrCode = '',
        itemsToPost;
    Object.keys(this.currentItems).forEach(key => {
      itemsToPost = this.currentItems[key].items; 
      qrCode = this.currentItems[key].items[0].outlet_qr_code;
    });

    const jsonData = {
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      email: this.email,
      current_items: itemsToPost,
      payment_method: this.paymentMethod,
      qr_code: qrCode,
      transaction_number: this.transactionNumber
    };

    axios.post(`${config.API_URL}/self-ordering/payment-gateway-order-v2`, jsonData)
      .then(res => {
        this.loadingPayment = false;
        const data = res.data;
        if (data.error) {
          this.alertCtrl.create({
            header: 'Error',
            message: data.error_message,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.eventsService.publish('home:clear_cart');
          if (this.paymentMethod !== 'Cash') {
            const paymentUrl = data.payment_url;
            this.modalCtrl.dismiss().then(() => {
              this.navCtrl.pop().then(() => {
                this.eventsService.publish('open_payment_url', paymentUrl);
              });
            });
          } else {
            this.modalCtrl.dismiss().then(() => {
              this.navCtrl.pop().then(() => {
                this.alertCtrl.create({
                  header: 'Success',
                  message: data.message,
                  buttons: ['Ok']
                }).then(alert => {
                  alert.present();
                });
              });
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.loadingPayment = false;
        if (error.response !== undefined && (error.response.status === 422 || error.response.status === '422')) {
          const firstKey = Object.keys(error.response.data.errors)[0];
          const firstError = error.response.data.errors[firstKey][0];
          this.alertCtrl.create({
            header: 'Error',
            message: firstError,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.alertCtrl.create({
            header: 'Error',
            message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        }
      });
  }

  checkoutMultiple(paymentType = null) {
    this.loadingPayment = true;

    if (paymentType !== null && paymentType !== undefined && paymentType.length > 0) {
      this.paymentMethod = paymentType;
    }

    const jsonData = {
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      email: this.email,
      current_items: this.currentItems,
      payment_method: this.paymentMethod,
      qr_code: this.qrCode,
      transaction_number: this.transactionNumber
    };

    axios.post(`${config.API_URL}/self-ordering/multiple/payment-gateway-order`, jsonData)
      .then(res => {
        this.loadingPayment = false;
        const data = res.data;
        if (data.error) {
          this.alertCtrl.create({
            header: 'Error',
            message: data.error_message,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.eventsService.publish('home:clear_cart');
          if (this.paymentMethod !== 'Cash') {
            const paymentUrl = data.payment_url;
            this.modalCtrl.dismiss().then(() => {
              this.navCtrl.pop().then(() => {
                this.eventsService.publish('open_payment_url', paymentUrl);
              });
            });
          } else {
            this.modalCtrl.dismiss().then(() => {
              this.navCtrl.pop().then(() => {
                this.alertCtrl.create({
                  header: 'Success',
                  message: data.message,
                  buttons: ['Ok']
                }).then(alert => {
                  alert.present();
                });
              });
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.loadingPayment = false;
        if (error.response !== undefined && (error.response.status === 422 || error.response.status === '422')) {
          const firstKey = Object.keys(error.response.data.errors)[0];
          const firstError = error.response.data.errors[firstKey][0];
          this.alertCtrl.create({
            header: 'Error',
            message: firstError,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        } else {
          this.alertCtrl.create({
            header: 'Error',
            message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        }
      });
  }

  checkoutWithPaymentGateway() {
    this.currentStep = 'payment_gateway';
  }

  checkoutWithCash() {
    this.currentStep = 'cash_payment';
  }

  ngOnInit() {
  }

}
